import { lazy, Suspense } from "react";

import { RouterWrapper } from "@bees-grow-shared/business-components";
import { Card } from "@hexa-ui/components";

import * as Styled from "./App.styles";
import { Container as ClubBContainer } from "./components/clubB/ClubB.styles";
import { Container as ClubBTabsContainer } from "./components/clubB/components/clubBTabs/ClubBTabs.styles";
import { LoadingBehavior as ChallengeGridLoadingBehavior } from "./components/clubB/components/clubBTabs/components/pointsBasedOffers/components/challengeGrid/ChallengeGrid";
import {
  Container as PointsBasedOffersContainer,
  OverviewContainer as PointsBasedOffersOverviewContainer,
  OverviewStatusesContainer as PointsBasedOffersOverviewStatusesContainer,
} from "./components/clubB/components/clubBTabs/components/pointsBasedOffers/PointsBasedOffers.styles";
import { EnvProvider } from "./components/envProvider/EnvProvider";
import { EnvConfig } from "./components/envProvider/EnvProvider.types";
import { Container as LeveragePageContainer } from "./pages/leveragePage/LeveragePage.styles";

const OptimizelyWrapper = lazy(() =>
  import("@bees-grow-shared/business-components").then((module) => ({
    default: module.OptimizelyWrapper,
  })),
);

const AnalyticsWrapper = lazy(() =>
  import("@bees-grow-shared/business-components").then((module) => ({
    default: module.AnalyticsWrapper,
  })),
);

const UserConfigWrapper = lazy(() =>
  import("@bees-grow-shared/business-components").then((module) => ({
    default: module.UserConfigWrapper,
  })),
);

const ThemeProvider = lazy(() =>
  import("@bees-grow-shared/theme").then((module) => ({
    default: module.ThemeProvider,
  })),
);

const IntlProvider = lazy(() =>
  import("@/i18n").then((module) => ({ default: module.IntlProvider })),
);

const LeveragePage = lazy(() => import("@/pages/leveragePage/LeveragePage"));

export default function App(props: Readonly<EnvConfig>) {
  return (
    <EnvProvider env={props}>
      <Suspense fallback={<LoadingBehavior />}>
        <ThemeProvider>
          <UserConfigWrapper>
            <OptimizelyWrapper sdkKey={props.OPTIMIZELY_KEY}>
              <AnalyticsWrapper segmentKey={props.SEGMENT_KEY}>
                <IntlProvider>
                  <RouterWrapper>
                    <LeveragePage />
                  </RouterWrapper>
                </IntlProvider>
              </AnalyticsWrapper>
            </OptimizelyWrapper>
          </UserConfigWrapper>
        </ThemeProvider>
      </Suspense>
    </EnvProvider>
  );
}

function LoadingBehavior() {
  return (
    <LeveragePageContainer>
      <ClubBContainer>
        <Styled.LoadingClubBHeader />
        <Card elevated="small" border="medium">
          <ClubBTabsContainer>
            <Styled.LoadingClubBTabs />
            <PointsBasedOffersContainer>
              <PointsBasedOffersOverviewStatusesContainer>
                <PointsBasedOffersOverviewContainer>
                  <Styled.LoadingOverviewTitle />
                  <Styled.LoadingOverviewDescription />
                </PointsBasedOffersOverviewContainer>
                <Styled.LoadingRewardsStatus />
              </PointsBasedOffersOverviewStatusesContainer>
              <ChallengeGridLoadingBehavior />
            </PointsBasedOffersContainer>
          </ClubBTabsContainer>
        </Card>
      </ClubBContainer>
    </LeveragePageContainer>
  );
}
