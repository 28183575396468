import { useIntl } from "react-intl";

import { useCurrencyFormatter } from "@bees-grow-shared/services";

import { ProgressBarProps } from "../ProgressBar";

export default function useProgressBar({
  executionMethod,
  quantity,
  quantityLeft,
  quantityPurchased,
}: ProgressBarProps) {
  const shouldNotRender =
    quantity === 0 || ["TAKE_PHOTO", "PURCHASE"].includes(executionMethod);

  const { formatMessage } = useIntl();

  const minimumFractionDigits = 2;
  const maximumFractionDigits = 2;

  const quantityInCurrency = useCurrencyFormatter(quantity, {
    minimumFractionDigits,
    maximumFractionDigits,
  });
  const quantityLeftInCurrency = useCurrencyFormatter(quantityLeft, {
    minimumFractionDigits,
    maximumFractionDigits,
  });
  const quantityPurchasedInCurrency = useCurrencyFormatter(quantityPurchased, {
    minimumFractionDigits,
    maximumFractionDigits,
  });

  const isCurrency = executionMethod === "PURCHASE_MULTIPLE_CURRENCY_FIXED";

  const purchased = isCurrency
    ? `${quantityPurchasedInCurrency}/${quantityInCurrency}`
    : formatMessage(
        {
          id: "ClubB.Content.PointsBasedOffers.ChallengeCards.ProgressMeter.PURCHASED_BY_QUANTITY",
        },
        {
          quantityPurchased: quantityPurchased,
          quantity: quantity,
        },
      );

  const leftToComplete = formatMessage(
    {
      id: isCurrency
        ? "ClubB.Content.PointsBasedOffers.ChallengeCards.ProgressMeter.LEFT_BY_CURRENCY"
        : "ClubB.Content.PointsBasedOffers.ChallengeCards.ProgressMeter.LEFT_BY_QUANTITY",
    },
    {
      quantityLeft: isCurrency ? quantityLeftInCurrency : quantityLeft,
    },
  );

  return { shouldNotRender, purchased, leftToComplete };
}
