import { SkeletonLoader } from "@hexa-ui/components";
import styled from "styled-components";

export const LoadingClubBHeader = styled(SkeletonLoader)`
  width: 4.1875rem;
  height: 2rem;
`;

export const LoadingClubBTabs = styled(SkeletonLoader)`
  width: 100%;
  height: 3.25rem;
`;

export const LoadingRewardsStatus = styled(SkeletonLoader)`
  width: 48.4375rem;
  height: 3.25rem;
`;

export const LoadingOverviewTitle = styled(SkeletonLoader)`
  width: 4.5625rem;
  height: 1.5rem;
`;

export const LoadingOverviewDescription = styled(SkeletonLoader)`
  width: 20.1875rem;
  height: 1.25rem;
`;
