import { tokens } from "@bees-grow-shared/theme";
import { Tabs } from "@hexa-ui/components";
import styled from "styled-components";

export const Container = styled.div`
  margin-top: ${tokens.measure.space["spacing-2"]};
  margin-left: ${tokens.measure.space["spacing-6"]};
  margin-right: ${tokens.measure.space["spacing-6"]};
  margin-bottom: ${tokens.measure.space["spacing-6"]};
`;

export const TabsTrigger = styled(Tabs.Trigger)`
  font-family: ${tokens.fonts["font-family"].Barlow} !important;
`;

export const TabsContent = styled(Tabs.Content)`
  padding: 0;
`;
