import { useCallback, useState } from "react";

import { ItemCard } from "@bees-grow-shared/business-components";
import { Drawer } from "@bees-grow-shared/components";
import {
  Challenge,
  Product,
  useDefaultAnalyticsData,
} from "@bees-grow-shared/services";

import { SCREEN_NAME } from "@/config/constants";
import analytics from "@/config/typewriter";

import * as Styled from "./ChallengeCard.styles";
import Body from "./components/body/Body";
import DrawerBody from "./components/drawerBody/DrawerBody";
import Header from "./components/header/Header";
import ProgressBar from "./components/progressBar/ProgressBar";
import Root from "./components/root/Root";
import Tags from "./components/tags/Tags";

function ChallengeCard({ challenge }: { challenge: Challenge }) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const defaultData = useDefaultAnalyticsData();

  const handleDrawerOpen = () => {
    if (!drawerOpen) trackClubBDetailsClicked();
    setDrawerOpen(!drawerOpen);
  };

  const trackClubBDetailsClicked = useCallback(() => {
    analytics.buttonClicked({
      ...defaultData,
      button_label: challenge.title,
      button_name: "single_rewards_challenge_details",
      challenge_type: challenge.executionMethod,
      url: location.host,
      screen_name: SCREEN_NAME,
      component_name: "ChallengeCard",
      calling_method: null,
    });
  }, [challenge.executionMethod, challenge.title, defaultData]);

  return (
    <Drawer.Root open={drawerOpen} setOpen={setDrawerOpen}>
      <Root onClick={handleDrawerOpen} data-testid="challenge-card">
        <Drawer.Trigger>
          <Header src={challenge.imgUrl} title={challenge.title} />
          <Body>
            <Tags tags={challenge.tags} />
            <ProgressBar
              executionMethod={challenge.executionMethod}
              quantity={challenge.quantity}
              quantityLeft={challenge.quantityLeft}
              quantityPurchased={challenge.quantityPurchased}
            />
          </Body>
        </Drawer.Trigger>
      </Root>
      <Drawer.Portal open={drawerOpen}>
        <Drawer.Overlay>
          <Styled.Overlay />
        </Drawer.Overlay>
        <Drawer.Content>
          <Styled.DrawerBody>
            <Drawer.Header setOpen={setDrawerOpen}>
              <Styled.DrawerTitle>{challenge.title}</Styled.DrawerTitle>
            </Drawer.Header>
            <Drawer.Body>
              <DrawerBody challenge={challenge} />
              <Styled.ProductsContainer>
                {challenge?.products
                  ?.filter((product) => !!product?.variants?.length)
                  .map((product: Product) => (
                    <ItemCard.Product
                      key={product?.variants[0]?.sku}
                      variant="clubB"
                      item={product}
                    />
                  ))}
              </Styled.ProductsContainer>
            </Drawer.Body>
          </Styled.DrawerBody>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
}

export default ChallengeCard;
