import { tokens } from "@bees-grow-shared/theme";
import { Paragraph, ProgressMeter } from "@hexa-ui/components";
import styled from "styled-components";

interface InfoLabelProps {
  position: "center" | "right";
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.measure.space["spacing-2"]};
`;

export const InfoLabel = styled(Paragraph)<InfoLabelProps>`
  font-family: ${tokens.fonts["font-family"]["Work Sans"]};
  font-size: ${tokens.measure["font-size"]["fontsize-2"]};
  font-weight: ${tokens.fonts["font-weight"].normal};
  line-height: ${tokens.measure["line-height"]["lineheight-5"]};
  align-self: ${(props) => {
    if (props.position === "center") return "center";
    else return "flex-end";
  }};
`;

export const ChallengeProgressMeter = styled(ProgressMeter)`
  & > progress {
    border-radius: ${tokens.measure["border-radius"]["borderradius-4"]};
    width: 100%;
    height: 0.5rem;
  }
  & > progress::-webkit-progress-bar {
    background-color: ${tokens.color.component.bg["progress"].track.default};

    border-radius: ${tokens.measure["border-radius"]["borderradius-4"]};
    height: 0.5rem;
  }
  & > progress::-webkit-progress-value {
    background-color: ${tokens.color.component.bg["data-vis"]["datablue-70"]};
    border-radius: ${tokens.measure["border-radius"]["borderradius-4"]};
    height: 0.5rem;
  }
`;
