import { tokens } from "@bees-grow-shared/theme";
import { Paragraph } from "@hexa-ui/components";
import styled from "styled-components";

export const CardImg = styled.img`
  width: 100%;
  height: 8.9375rem;
`;

export const CardTitle = styled(Paragraph)`
  font-family: Barlow;
  font-size: ${tokens.measure["font-size"]["fontsize-3"]};
  font-weight: ${tokens.fonts["font-weight"].semibold};
  line-height: ${tokens.measure["line-height"]["lineheight-5"]};
  margin: 0.75rem 0.75rem 0.75rem 1rem;
  text-align: left;
`;
