import { tokens } from "@bees-grow-shared/theme";
import { Heading as HexaHeading, Paragraph } from "@hexa-ui/components";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${tokens.measure.space["spacing-4"]};
  gap: ${tokens.measure.space["spacing-8"]};
`;

export const BehaviorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: ${tokens.measure.space["spacing-4"]};
  margin: ${tokens.measure.space["spacing-8"]} 0;
  gap: ${tokens.measure.space["spacing-4"]};
`;

export const ErrorBehaviorParagraph = styled(Paragraph)`
  width: 12.25rem;
  line-height: ${tokens.measure["line-height"]["lineheight-5"]};
`;

export const EmptyBehaviorParagraph = styled(Paragraph)`
  width: 11.25rem;
  line-height: ${tokens.measure["line-height"]["lineheight-5"]};
`;

export const OverviewStatusesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.measure.space["spacing-4"]};
`;

export const OverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.measure.space["spacing-1"]};
`;

export const Heading = styled(HexaHeading)`
  font: ${tokens.fonts["font-family"]["Work Sans"]};
  line-height: ${tokens.measure["line-height"]["lineheight-6"]};
`;
