import useProgressBar from "./hooks/useProgressBar";
import * as Styled from "./ProgressBar.styles";

export interface ProgressBarProps {
  executionMethod: string;
  quantity: number;
  quantityLeft: number;
  quantityPurchased: number;
}

function ProgressBar(props: ProgressBarProps) {
  const { quantityPurchased, quantity } = props;

  const { shouldNotRender, purchased, leftToComplete } = useProgressBar(props);

  if (shouldNotRender) return null;

  return (
    <Styled.Container>
      <Styled.InfoLabel position="right" data-testid="info-label-top">
        {purchased}
      </Styled.InfoLabel>

      <Styled.ChallengeProgressMeter
        value={quantityPurchased}
        maxValue={quantity}
      />

      <Styled.InfoLabel position="center" data-testid="info-label-bottom">
        {leftToComplete}
      </Styled.InfoLabel>
    </Styled.Container>
  );
}

export default ProgressBar;
