import * as Styles from "./Root.styles";

interface RootProps {
  children: React.ReactNode;
  onClick?: () => void;
  "data-testid"?: string;
}

function Root({ children, onClick, "data-testid": dataTestId }: RootProps) {
  return (
    <Styles.RootCard onClick={onClick} data-testid={dataTestId}>
      {children}
    </Styles.RootCard>
  );
}

export default Root;
