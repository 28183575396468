import { Tag as TagDomain } from "@bees-grow-shared/services";

import ChallengeTag from "./components/challengeTag/ChallengeTag";
import * as Styled from "./Tags.style";

function Tags({ tags }: { tags: TagDomain[] }) {
  return (
    <Styled.Container>
      {tags.map((tag) => (
        <ChallengeTag
          data-testid={`${tag.type}-${tag.value}`}
          key={`${tag.type}-${tag.value}`}
          tag={tag}
        />
      ))}
    </Styled.Container>
  );
}

export default Tags;
