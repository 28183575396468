import { tokens } from "@bees-grow-shared/theme";
import { Paragraph } from "@hexa-ui/components";
import styled from "styled-components";

export const DrawerBody = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  & > div:last-child {
    overflow-y: auto;
  }
`;

export const DrawerTitle = styled(Paragraph)`
  font-family: ${tokens.fonts["font-family"].Barlow} !important;
  font-size: ${tokens.measure["font-size"]["fontsize-6"]};
  line-height: ${tokens.measure["line-height"]["lineheight-7"]};
  font-weight: ${tokens.fonts["font-weight"].semibold};
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const ProductsContainer = styled.div`
  padding-top: 1rem;
  & > div:not(:last-child) {
    border-radius: unset;
    border-bottom: 1px solid ${tokens.color.foundation.border.secondary};
  }
`;
