import { useIntl } from "react-intl";

import { Tag as TagDomain } from "@bees-grow-shared/services";

export default function useChallengeTag({ tag }: { tag: TagDomain }) {
  const { formatMessage } = useIntl();

  let label = "";
  let color = undefined;

  if (tag.type === "REMAINING_DAYS") {
    label =
      tag.value === 1
        ? formatMessage({
            id: "ClubB.Content.PointsBasedOffers.ChallengeCards.Tags.LAST_DAY",
          })
        : formatMessage(
            {
              id: "ClubB.Content.PointsBasedOffers.ChallengeCards.Tags.X_DAYS",
            },
            { value: tag.value },
          );

    color = tag.value > 10 ? "blue" : "orange";
  }

  if (tag.type === "POINTS") {
    label = formatMessage(
      {
        id: "ClubB.Content.PointsBasedOffers.ChallengeCards.Tags.POINTS",
      },
      { value: tag.value },
    );
    color = "gray";
  }

  if (tag.type === "NEW") {
    label = formatMessage({
      id: "ClubB.Content.PointsBasedOffers.ChallengeCards.Tags.NEW",
    });
    color = "blue";
  }

  if (tag.type === "RETAKE_PHOTO") {
    label = formatMessage({
      id: "ClubB.Content.PointsBasedOffers.ChallengeCards.Tags.RETAKE_PHOTO",
    });
    color = "red";
  }

  return { label, color };
}
