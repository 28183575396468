import { useCallback, useEffect, useMemo } from "react";

import {
  accountSharedService,
  challengesSharedService,
  useSharedUnit,
} from "@bees-grow-shared/services";

export function useChallengeGrid() {
  const accountService = accountSharedService();
  const challengesService = challengesSharedService();

  const {
    vendorId,
    accountId,
    isLoading: isAccountLoading,
  } = useSharedUnit(accountService);
  const {
    challenges,
    scrollPosition,
    hasNextPage,
    firstPageStatus: {
      isLoading: isFirstPageStatusLoading,
      done: isFirstPageStatusDone,
    },
    nextPageStatus: {
      isLoading: isNextPageStatusLoading,
      error: isNextPageStatusError,
      done: isNextPageStatusDone,
    },
  } = useSharedUnit(challengesService);

  const isLoading = useMemo(
    () => isAccountLoading || isFirstPageStatusLoading,
    [isAccountLoading, isFirstPageStatusLoading],
  );

  useEffect(() => {
    if (accountId && vendorId && !isFirstPageStatusDone) {
      challengesService.getChallenges();
    }
  }, [accountId, challengesService, isFirstPageStatusDone, vendorId]);

  const getNextPage = useCallback(() => {
    challengesService.getNextPageChallenges();
  }, [challengesService]);

  const setScrollPosition = useCallback(() => {
    challengesService.setChallenges({ scrollPosition: window.scrollY });
  }, [challengesService]);

  return {
    isLoading,
    challenges,
    scrollPosition,
    hasNextPage,
    isNextPageStatusLoading,
    isNextPageStatusError,
    isNextPageStatusDone,
    getNextPage,
    setScrollPosition,
  };
}
