import { sanitize } from "dompurify";

import * as Styles from "./Header.styles";

interface HeadProps {
  src: string;
  title: string;
}

function Header({ src, title }: HeadProps) {
  const sanitizedSrc = sanitize(src);

  return (
    <div>
      <Styles.CardImg src={sanitizedSrc} alt={title} />
      <Styles.CardTitle>{title}</Styles.CardTitle>
    </div>
  );
}

export default Header;
