import { tokens } from "@bees-grow-shared/theme";
import { Alert, Paragraph } from "@hexa-ui/components";
import styled from "styled-components";

interface LabelProps {
  weight: "normal" | "semibold";
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const challengeAlert = styled(Alert)`
  width: 100%;
`;

export const Table = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;

  li:nth-child(even) {
    background: #fafafa;
  }
`;

export const Row = styled.li`
  display: flex;
  justify-content: space-between;
  height: 2rem;
  padding: 0.25rem 0 0.25rem 0;
`;

export const Label = styled(Paragraph)<LabelProps>`
  font-family: ${tokens.fonts["font-family"]["Work Sans"]};
  font-size: ${tokens.measure["font-size"]["fontsize-2"]};
  line-height: ${tokens.measure["line-height"]["lineheight-5"]};
  font-weight: ${(props) => {
    if (props.weight === "normal") return tokens.fonts["font-weight"].normal;
    else return tokens.fonts["font-weight"].semibold;
  }};
`;

export const ImageExampleContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr auto;
  column-gap: 0.125rem;
  padding-bottom: 0.75rem;
`;

export const CorrectPhotoIndicator = styled.div`
  padding: 0.438rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 0.25rem solid #009900;
  color: #009900;
`;

export const WrongPhotoIndicator = styled.div`
  padding: 0.438rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 0.25rem solid #e82727;
  color: #e82727;
`;

export const ImageExample = styled.img`
  height: 100%;
  width: 100%;
  max-height: 17.75rem;
  border-radius: ${tokens.measure["border-radius"]["borderradius-2"]};
`;

export const ChallengeDetailsImg = styled.img`
  width: 100%;
  height: 12.5rem;
  border-radius: ${tokens.measure["border-radius"]["borderradius-2"]};
`;

export const ChallengeDescription = styled(Paragraph)`
  font-family: ${tokens.fonts["font-family"]["Work Sans"]};
  font-size: ${tokens.measure["font-size"]["fontsize-3"]};
  line-height: ${tokens.measure["line-height"]["lineheight-6"]};
  font-weight: ${tokens.fonts["font-weight"].normal};
  width: 100%;
`;
