import { tokens } from "@bees-grow-shared/theme";
import styled from "styled-components";

export const RootCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  border: 0.0625rem solid
    ${tokens.color.primitive.transparent.black["black-12"]};
  cursor: pointer;
  overflow: hidden;
`;
